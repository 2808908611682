function copyIP() {
  const ip = "mc.creepycraft.dk";
  const button = document.querySelector(".copy-button");

  navigator.clipboard.writeText(ip).then(() => {
    button.classList.add("copy-success");

    setTimeout(() => {
      button.classList.remove("copy-success");
    }, 2000);
  });
}

const collapsibles = document.querySelectorAll(".collapsible");
collapsibles.forEach((item) =>
  item.addEventListener("click", function () {
    this.classList.toggle("collapsible--expanded");
  })
);
